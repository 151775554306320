.sidebar {
  min-height: calc(100vh - 100px);
  height: 100%;

  .sidebar-content {
    min-height: calc(100vh - 100px);
  }

  .qr__code{
    padding: 20px;
  }
}

.sublinks {
  padding: 0;
  margin: 0;
  padding-bottom: 5px;

  li {
    padding: 4px 0 0 46px;

    li {
      padding: 0 0 0 20px;
    }
  }
}